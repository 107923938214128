import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWallet } from './WalletContext';
import { Connection, PublicKey, Transaction, SystemProgram, LAMPORTS_PER_SOL } from '@solana/web3.js';
import { ethers } from 'ethers';
import { PythHttpClient, getPythProgramKeyForCluster } from '@pythnetwork/client';
import './Sidebar.css';

const SOLANA_NETWORK = 'https://api.devnet.solana.com'; // Switch to custom RPC or Mainnet in production
const CHAINLINK_ETH_USD_ADDRESS = '0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419'; // Mainnet Chainlink ETH/USD feed

const Sidebar = () => {
  const navigate = useNavigate();
  const { walletState, updateWallet, disconnectWallet } = useWallet();
  const [isOpen, setIsOpen] = useState(true);
  const [solPrice, setSolPrice] = useState(null);
  const [ethPrice, setEthPrice] = useState(null);
  const [sendAmount, setSendAmount] = useState('');
  const [sendAddress, setSendAddress] = useState('');
  const [swapAmount, setSwapAmount] = useState('');
  const [isLoadingPrices, setIsLoadingPrices] = useState(false);
  const [message, setMessage] = useState({ text: '', type: '' });

  useEffect(() => {
    const fetchPrices = async () => {
      setIsLoadingPrices(true);
      try {
        if (walletState.type === 'solana' && window.solana) {
          const connection = new Connection(SOLANA_NETWORK, 'confirmed');
          const pythClient = new PythHttpClient(connection, getPythProgramKeyForCluster('devnet')); // Update to 'mainnet-beta' for production
          const data = await pythClient.getData();
          const solUsdPrice = data.productPrice.get('Crypto.SOL/USD')?.price;
          setSolPrice(solUsdPrice || null);
        } else if (walletState.type === 'ethereum' && window.ethereum) {
          const provider = new ethers.BrowserProvider(window.ethereum);
          const chainlinkContract = new ethers.Contract(
            CHAINLINK_ETH_USD_ADDRESS,
            ['function latestRoundData() external view returns (uint80, int256, uint256, uint256, uint80)'],
            provider
          );
          const roundData = await chainlinkContract.latestRoundData();
          const ethUsdPrice = Number(roundData[1]) / 1e8;
          setEthPrice(ethUsdPrice);
        }
      } catch (err) {
        console.error('Sidebar: Failed to fetch prices:', err);
        if (err.message.includes('403')) {
          setMessage({ text: 'Access to price feed forbidden. Using cached data.', type: 'error' });
          setTimeout(() => setMessage({ text: '', type: '' }), 3000);
        }
      } finally {
        setIsLoadingPrices(false);
      }
    };
    if (walletState.connected) {
      fetchPrices();
    }
  }, [walletState.connected, walletState.type]);

  const handleDisconnect = async () => {
    console.log('Sidebar: Disconnect button clicked');
    if (walletState.type === 'solana' && window.solana) {
      try {
        await window.solana.disconnect();
      } catch (err) {
        console.error('Sidebar: Solana disconnect failed:', err);
      }
    }
    disconnectWallet();
  };

  const refreshStatus = async () => {
    console.log('Sidebar: Refresh status clicked');
    if (walletState.address && walletState.type) {
      await updateWallet(walletState.type, walletState.address);
    }
  };

  const copyAddress = () => {
    if (walletState.address) {
      navigator.clipboard.writeText(walletState.address)
        .then(() => setMessage({ text: 'Address copied to clipboard!', type: 'success' }))
        .catch(err => {
          console.error('Sidebar: Failed to copy address:', err);
          setMessage({ text: 'Failed to copy address.', type: 'error' });
        });
      setTimeout(() => setMessage({ text: '', type: '' }), 3000);
    }
  };

  const handleSendAmountChange = (e) => {
    const value = e.target.value.trim();
    const numValue = parseFloat(value);
    if (value === '' || (numValue > 0 && !isNaN(numValue))) {
      setSendAmount(value);
    } else {
      setSendAmount('');
    }
  };

  const sendNativeCoin = async () => {
    if (!sendAmount || !sendAddress) {
      setMessage({ text: 'Please enter a valid amount and address.', type: 'error' });
      return;
    }
    const amountNum = parseFloat(sendAmount);
    if (isNaN(amountNum) || amountNum <= 0) {
      setMessage({ text: 'Amount must be greater than 0.', type: 'error' });
      return;
    }

    try {
      if (walletState.type === 'solana' && window.solana) {
        const connection = new Connection(SOLANA_NETWORK, 'confirmed');
        const fromPubkey = new PublicKey(walletState.address);
        const toPubkey = new PublicKey(sendAddress);
        const lamports = Math.floor(amountNum * LAMPORTS_PER_SOL);

        const transaction = new Transaction().add(
          SystemProgram.transfer({
            fromPubkey,
            toPubkey,
            lamports,
          })
        );

        const signature = await window.solana.signAndSendTransaction(transaction);
        await connection.confirmTransaction(signature, 'confirmed');
        setMessage({ text: 'SOL sent successfully!', type: 'success' });
      } else if (walletState.type === 'ethereum' && window.ethereum) {
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner();
        const tx = {
          to: sendAddress,
          value: ethers.parseEther(sendAmount.toString()),
        };
        const receipt = await signer.sendTransaction(tx);
        await receipt.wait();
        setMessage({ text: 'ETH sent successfully!', type: 'success' });
      }
      setSendAmount('');
      setSendAddress('');
      await refreshStatus();
    } catch (err) {
      console.error('Sidebar: Send failed:', err);
      setMessage({ text: `Failed to send coins: ${err.message}`, type: 'error' });
    }
    setTimeout(() => setMessage({ text: '', type: '' }), 3000);
  };

  const swapTokens = async () => {
    if (!swapAmount) {
      setMessage({ text: 'Please enter swap amount.', type: 'error' });
      return;
    }
    setMessage({ text: 'Swap feature not fully implemented. This is a placeholder for DEX integration.', type: 'info' });
    setSwapAmount('');
    setTimeout(() => setMessage({ text: '', type: '' }), 3000);
  };

  const formatBalance = (balance, price, currency) => {
    if (!balance) return `0.00 ${currency}`;
    let formattedBalance;
    try {
      if (walletState.type === 'solana') {
        formattedBalance = (parseFloat(balance) / LAMPORTS_PER_SOL).toFixed(2);
      } else if (walletState.type === 'ethereum') {
        const balanceNum = parseFloat(balance);
        if (isNaN(balanceNum) || balanceNum < 0) {
          formattedBalance = '0.00';
        } else {
          formattedBalance = ethers.formatEther(balance.toString());
        }
      } else {
        formattedBalance = '0.00';
      }
    } catch (err) {
      console.error('Sidebar: Format balance error:', err);
      formattedBalance = '0.00';
    }
    if (!price || isLoadingPrices) return `${formattedBalance} ${currency}`;
    const usdValue = (parseFloat(formattedBalance) * price).toFixed(2);
    return `${formattedBalance} ${currency} ($${usdValue})`;
  };

  return (
    <div className="sidebar-container">
      <button className="sidebar-toggle" onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? '✕ Wallet' : '☰ Wallet'}
      </button>
      {isOpen && (
        <div className="sidebar">
          <h2>Wallet Info</h2>
          <div className="wallet-details">
            <p>
              <strong>Address:</strong>
              {walletState.address ? `${walletState.address.slice(0, 6)}...${walletState.address.slice(-4)}` : 'Not connected'}
              {walletState.address && <button className="copy-btn" onClick={copyAddress}>Copy</button>}
            </p>
            <p><strong>Type:</strong> {walletState.type ? walletState.type.charAt(0).toUpperCase() + walletState.type.slice(1) : 'N/A'}</p>
            <p><strong>Network:</strong> {walletState.type ? walletState.type.charAt(0).toUpperCase() + walletState.type.slice(1) : 'N/A'}</p>
            <p><strong>Status:</strong> <span className={`status-${walletState.connected ? 'connected' : 'disconnected'}`}>{walletState.connected ? 'connected' : 'disconnected'}</span></p>
            <p><strong>Native Balance:</strong> {formatBalance(walletState.nativeBalance, walletState.type === 'solana' ? solPrice : ethPrice, walletState.type === 'solana' ? 'SOL' : 'ETH')}</p>
            <p><strong>Token Balance:</strong> {walletState.tokenBalance ? `${walletState.tokenBalance} ${walletState.coinDetails.symbol || 'TOKEN'}` : 'N/A'}</p>
          </div>
          {message.text && (
            <div className={`message-box ${message.type}`}>
              {message.text}
            </div>
          )}
          <div className="sidebar-actions">
            {walletState.connected && (
              <>
                <input
                  type="number"
                  value={sendAmount}
                  onChange={handleSendAmountChange}
                  placeholder="Amount to send"
                  min="0.000001"
                  step="0.000001"
                  style={{ width: '100%', padding: '8px', marginBottom: '8px', borderRadius: '10px', border: 'none', background: '#333333', color: '#ffffff' }}
                />
                <input
                  type="text"
                  value={sendAddress}
                  onChange={(e) => setSendAddress(e.target.value)}
                  placeholder="Recipient address"
                  style={{ width: '100%', padding: '8px', marginBottom: '8px', borderRadius: '10px', border: 'none', background: '#333333', color: '#ffffff' }}
                />
                <button onClick={sendNativeCoin} className="sidebar-btn">Send</button>
                <input
                  type="number"
                  value={swapAmount}
                  onChange={(e) => setSwapAmount(e.target.value)}
                  placeholder="Amount to swap"
                  style={{ width: '100%', padding: '8px', marginBottom: '8px', borderRadius: '10px', border: 'none', background: '#333333', color: '#ffffff' }}
                />
                <button onClick={swapTokens} className="sidebar-btn">Swap</button>
                <button onClick={handleDisconnect} className="sidebar-btn disconnect-btn">Disconnect</button>
                <button onClick={refreshStatus} className="sidebar-btn refresh-btn">Refresh</button>
              </>
            )}
          </div>
          <div className="sidebar-links">
            <h3>Resources</h3>
            <ul>
              <li><a href="https://ethereum.org" target="_blank" rel="noopener noreferrer">Ethereum Docs</a></li>
              <li><a href="https://solana.com" target="_blank" rel="noopener noreferrer">Solana Docs</a></li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sidebar;