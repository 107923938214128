import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  const navigate = useNavigate();

  const handleHomeClick = () => {
    navigate('/');
  };

  const handleCoinCreatorClick = () => {
    navigate('/coincreator');
  };

  return (
    <nav className="navbar">
      <div className="navbar-content">
        <div className="navbar-brand">
          <Link to="/" className="navbar-logo">Meme Coin Creator</Link>
        </div>
        <ul className="navbar-links">
          <li>
            <button onClick={handleHomeClick} className="navbar-link">Home</button>
          </li>
          <li>
            <button onClick={handleCoinCreatorClick} className="navbar-link">Coin Creator</button>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;