import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { Connection, PublicKey, Transaction, SystemProgram, LAMPORTS_PER_SOL, Keypair } from '@solana/web3.js';
import { TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { Buffer } from 'buffer';
import { useWallet } from './WalletContext';
import './CoinCreator.css';

const SERVICE_WALLET_ADDRESS_ETH = 'YOUR_ETHEREUM_SERVICE_WALLET_ADDRESS_HERE'; // Replace with actual Ethereum address
const SERVICE_WALLET_ADDRESS_SOL = 'YOUR_SOLANA_SERVICE_WALLET_ADDRESS_HERE'; // Replace with actual Solana address
const SERVER_URL = 'http://localhost:3001'; // Update to production URL
const SOLANA_NETWORK = 'https://api.devnet.solana.com'; // Switch to custom RPC or Mainnet in production
const SOLANA_PROGRAM_ID = 'YOUR_ACTUAL_PROGRAM_ID_HERE'; // Replace with deployed Program Id

const CoinCreator = () => {
  const { walletState, setContractArtifact, setCoinDetails, setDeployedContract } = useWallet();
  const [coinDetails, setCoinDetailsState] = useState({
    name: '',
    symbol: '',
    supply: '',
    burn: false,
    renounceOwnership: false,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [deployedContractLocal, setDeployedContractLocal] = useState(null);

  useEffect(() => {
    const fetchArtifact = async () => {
      try {
        const response = await fetch(`${SERVER_URL}/api/contract-artifact`);
        if (!response.ok) throw new Error('Failed to fetch contract data from server');
        const artifact = await response.json();
        setContractArtifact(artifact);
      } catch (err) {
        console.error('CoinCreator: Failed to fetch contract artifact:', err);
        setError('Oops! We couldn’t load the contract data. Please try refreshing the page.');
      }
    };
    fetchArtifact();
  }, [setContractArtifact]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    let newValue = value;
    if (name === 'supply') {
      newValue = value.trim();
      if (newValue === '' || parseFloat(newValue) <= 0) {
        newValue = '';
      }
    }
    setCoinDetailsState(prev => {
      const newDetails = { ...prev, [name]: type === 'checkbox' ? checked : newValue };
      setCoinDetails(newDetails);
      return newDetails;
    });
  };

  const deployCoin = async () => {
    if (!walletState.connected) {
      setError('Please connect your wallet before deploying a coin.');
      return;
    }
    if (!coinDetails.name || !coinDetails.symbol || !coinDetails.supply) {
      setError('Please fill out all fields (Name, Symbol, and Total Supply) to create your coin.');
      return;
    }
    const supplyNum = parseFloat(coinDetails.supply);
    if (isNaN(supplyNum) || supplyNum <= 0) {
      setError('Total Supply must be a valid number greater than 0.');
      return;
    }
    if (walletState.type === 'ethereum' && !walletState.contractArtifact) {
      setError('Contract data isn’t ready yet. Please wait a moment and try again.');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      if (walletState.type === 'ethereum') {
        await deployEthereumCoin();
      } else if (walletState.type === 'solana') {
        await deploySolanaCoin();
      }
    } catch (err) {
      console.error('CoinCreator: Deployment error:', err);
      setError(`Something went wrong while deploying your coin: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const deployEthereumCoin = async () => {
    const provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();
    const walletAddress = await signer.getAddress();
    const supplyStr = coinDetails.supply.trim();
    if (!supplyStr || parseFloat(supplyStr) <= 0) {
      throw new Error('Supply must be greater than 0.');
    }
    const supply = ethers.parseEther(supplyStr);
    const fee = supply / 100n;
    const creatorSupply = supply - fee;

    const factory = new ethers.ContractFactory(walletState.contractArtifact.abi, walletState.contractArtifact.bytecode, signer);
    const contract = await factory.deploy(
      coinDetails.name,
      coinDetails.symbol,
      creatorSupply,
      walletAddress,
      { value: fee }
    );

    await contract.waitForDeployment();
    const contractAddress = await contract.getAddress();

    const tx = await contract.transferFee(SERVICE_WALLET_ADDRESS_ETH, fee);
    await tx.wait();

    if (coinDetails.renounceOwnership) {
      const renounceTx = await contract.renounceOwnership();
      await renounceTx.wait();
    }

    setDeployedContractLocal(contractAddress);
    setDeployedContract(contractAddress);
    await notifyServer('ethereum', contractAddress, walletAddress);
  };

  const deploySolanaCoin = async () => {
    if (!walletState.address || typeof walletState.address !== 'string') {
      throw new Error('Invalid wallet address: address is missing or not a string.');
    }

    const connection = new Connection(SOLANA_NETWORK, 'confirmed');
    let publicKey;
    try {
      publicKey = new PublicKey(walletState.address);
      console.log('Public Key:', publicKey.toString());
    } catch (err) {
      throw new Error('Invalid wallet address: ' + err.message);
    }

    if (!window.solana || !window.solana.isConnected) {
      throw new Error('Solana wallet is not connected.');
    }
    const payer = window.solana;

    const supply = Math.floor(parseFloat(coinDetails.supply) * LAMPORTS_PER_SOL);
    console.log('Supply (lamports):', supply);
    let programId;
    try {
      programId = new PublicKey(SOLANA_PROGRAM_ID);
      console.log('Program ID:', programId.toString());
    } catch (err) {
      throw new Error('Invalid program ID: ' + err.message);
    }

    const MAX_U64 = 18446744073709551615; // 2^64 - 1
    if (supply > MAX_U64) {
      throw new Error('Supply exceeds maximum u64 value for Solana.');
    }

    const mintKeypair = Keypair.generate();
    console.log('Mint Public Key:', mintKeypair.publicKey.toString());
    const rent = await connection.getMinimumBalanceForRentExemption(82);
    console.log('Rent (lamports):', rent);

    const supplyBuffer = Buffer.alloc(8);
    supplyBuffer.writeUInt32LE(supply & 0xFFFFFFFF, 0); // Lower 32 bits
    supplyBuffer.writeUInt32LE(Math.floor(supply / 0x100000000), 4); // Upper 32 bits
    console.log('Supply Buffer:', supplyBuffer.toString('hex'));

    const transaction = new Transaction();

    // Step 1: Create account for the mint
    const createAccountIx = SystemProgram.createAccount({
      fromPubkey: publicKey,
      newAccountPubkey: mintKeypair.publicKey,
      lamports: rent,
      space: 82, // Mint account size
      programId: TOKEN_PROGRAM_ID, // Owned by SPL Token program initially
    });
    transaction.add(createAccountIx);

    // Step 2: Initialize the mint via custom program
    const initMintIx = {
      keys: [
        { pubkey: publicKey, isSigner: true, isWritable: true }, // Payer
        { pubkey: mintKeypair.publicKey, isSigner: false, isWritable: true }, // Mint account
        { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false }, // SPL Token program
        { pubkey: SystemProgram.programId, isSigner: false, isWritable: false }, // System program
      ],
      programId,
      data: supplyBuffer,
    };
    transaction.add(initMintIx);

    console.log('Transaction Instructions:', transaction.instructions);

    try {
      const { blockhash, lastValidBlockHeight } = await connection.getLatestBlockhash();
      transaction.recentBlockhash = blockhash;
      transaction.feePayer = publicKey;
      console.log('Recent Blockhash:', blockhash);
      console.log('Last Valid Block Height:', lastValidBlockHeight);

      // Partially sign with mintKeypair (new account creator)
      transaction.partialSign(mintKeypair);
      console.log('Transaction after partial sign:', transaction);

      const signature = await payer.signAndSendTransaction(transaction);
      console.log('Transaction Signature:', signature);

      const confirmation = await connection.confirmTransaction({
        signature,
        blockhash,
        lastValidBlockHeight,
      });
      console.log('Confirmation:', confirmation);

      if (confirmation.value.err) {
        throw new Error('Transaction confirmation failed: ' + JSON.stringify(confirmation.value.err));
      }
    } catch (err) {
      console.error('Phantom Error Details:', err);
      if (err.message.includes('403')) {
        throw new Error('Access to Solana network forbidden. Try again later or check your RPC provider.');
      }
      throw new Error('Transaction failed: ' + (err.message || 'Unknown error from wallet'));
    }

    if (coinDetails.burn) {
      console.log('Burn feature not implemented in this example');
    }

    if (coinDetails.renounceOwnership) {
      console.log('Renounce ownership not implemented in this example');
    }

    setDeployedContractLocal(mintKeypair.publicKey.toString());
    setDeployedContract(mintKeypair.publicKey.toString());
    await notifyServer('solana', mintKeypair.publicKey.toString(), publicKey.toString());
  };

  const notifyServer = async (network, contractAddress, creator, serviceWallet = SERVICE_WALLET_ADDRESS_ETH) => {
    try {
      await fetch(`${SERVER_URL}/api/coin-deployed`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          network,
          creator,
          contractAddress,
          name: coinDetails.name,
          symbol: coinDetails.symbol,
          supply: coinDetails.supply,
          serviceWallet,
        }),
      });
    } catch (err) {
      console.error('CoinCreator: Failed to notify server:', err);
    }
  };

  return (
    <div className="coin-creator">
      <h1>Create Your Meme Coin</h1>
      {error && <div className="error-message">{error}</div>}
      <form className="coin-form">
        <div className="form-group">
          <label>Name</label>
          <input
            type="text"
            name="name"
            value={coinDetails.name}
            onChange={handleInputChange}
            placeholder="e.g., DogeCoin"
          />
        </div>
        <div className="form-group">
          <label>Symbol</label>
          <input
            type="text"
            name="symbol"
            value={coinDetails.symbol}
            onChange={handleInputChange}
            placeholder="e.g., DOGE"
          />
        </div>
        <div className="form-group">
          <label>Total Supply</label>
          <input
            type="number"
            name="supply"
            value={coinDetails.supply}
            onChange={handleInputChange}
            placeholder="e.g., 1000000"
            min="0.000001"
            step="0.000001"
            required
          />
        </div>
        <div className="form-group checkbox">
          <input
            type="checkbox"
            name="burn"
            checked={coinDetails.burn}
            onChange={handleInputChange}
          />
          <label>Enable Burning</label>
        </div>
        <div className="form-group checkbox">
          <input
            type="checkbox"
            name="renounceOwnership"
            checked={coinDetails.renounceOwnership}
            onChange={handleInputChange}
          />
          <label>Renounce Ownership</label>
        </div>
      </form>
      {!walletState.connected ? (
        <p>Please connect a wallet from the home page.</p>
      ) : (
        <button
          onClick={deployCoin}
          disabled={loading}
          className="deploy-btn"
        >
          {loading ? 'Deploying...' : 'Deploy Coin'}
        </button>
      )}
      {deployedContractLocal && (
        <div className="success-message">
          <p>Congratulations! Your coin is live.</p>
          <p>Contract Address: {deployedContractLocal}</p>
        </div>
      )}
    </div>
  );
};

export default CoinCreator;