import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { WalletProvider } from './WalletContext';
import WalletConnector from './WalletConnector';
import CoinCreator from './CoinCreator';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import './App.css';

const App = () => {
  return (
    <WalletProvider>
      <Router>
        <div className="app">
          <Navbar />
          <main className="main-content">
            <Routes>
              <Route path="/" element={<WalletConnector />} />
              <Route path="/coincreator" element={<CoinCreator />} />
            </Routes>
          </main>
          <Sidebar />
        </div>
      </Router>
    </WalletProvider>
  );
};

export default App;