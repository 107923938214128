import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ethers } from 'ethers';
import { useWallet } from './WalletContext';
import './WalletConnector.css';

const SERVER_URL = 'http://localhost:3001';
const WS_URL = 'ws://localhost:3001/ws';

const WalletConnector = () => {
  const navigate = useNavigate();
  const { updateWallet } = useWallet();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [serverStatus, setServerStatus] = useState('checking');
  const [ws, setWs] = useState(null);

  const connectWebSocket = () => {
    const websocket = new WebSocket(WS_URL);
    
    websocket.onopen = () => {
      console.log('WalletConnector: WebSocket connected');
    };

    websocket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.error) {
        setError(data.error);
      }
    };

    websocket.onerror = (error) => {
      console.error('WalletConnector: WebSocket error:', error);
      setError('WebSocket connection failed');
    };

    websocket.onclose = () => {
      console.log('WalletConnector: WebSocket disconnected, attempting to reconnect...');
      setTimeout(connectWebSocket, 2000);
    };

    setWs(websocket);
    return websocket;
  };

  useEffect(() => {
    const checkServerStatus = async () => {
      try {
        const response = await fetch(`${SERVER_URL}/api/ping`);
        if (response.ok) setServerStatus('online');
        else setServerStatus('offline');
      } catch (err) {
        setServerStatus('offline');
        console.error('WalletConnector: Server ping failed:', err);
      }
    };
    checkServerStatus();
    const websocket = connectWebSocket();
    return () => websocket.close();
  }, []);

  const connectEthWallet = async () => {
    if (!window.ethereum) {
      setError('Please install MetaMask!');
      return;
    }
    try {
      setLoading(true);
      setError(null);
      const provider = new ethers.BrowserProvider(window.ethereum);
      const accounts = await provider.send('eth_requestAccounts', []);
      if (accounts.length === 0) throw new Error('No accounts returned');
      console.log('WalletConnector: Ethereum connected:', accounts[0]);
      await fetch(`${SERVER_URL}/api/connect-wallet`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ address: accounts[0], type: 'ethereum' }),
      });
      await updateWallet('ethereum', accounts[0]);
      console.log('WalletConnector: Navigating to /coincreator');
      navigate('/coincreator');
    } catch (error) {
      console.error('WalletConnector: Ethereum connection error:', error);
      setError(`Ethereum connection failed: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const connectSolanaWallet = async () => {
    if (!window.solana) {
      setError('Please install Phantom wallet!');
      return;
    }
    try {
      setLoading(true);
      setError(null);
      const resp = await window.solana.connect();
      if (!resp.publicKey) throw new Error('No public key returned');
      console.log('WalletConnector: Solana connected:', resp.publicKey.toString());
      await fetch(`${SERVER_URL}/api/connect-wallet`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ address: resp.publicKey.toString(), type: 'solana' }),
      });
      await updateWallet('solana', resp.publicKey.toString());
      console.log('WalletConnector: Navigating to /coincreator');
      navigate('/coincreator');
    } catch (error) {
      console.error('WalletConnector: Solana connection error:', error);
      setError(`Solana connection failed: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="wallet-connector">
      <h1>Connect Your Wallet</h1>
      {serverStatus === 'offline' && (
        <div className="error-message">Server is offline - functionality limited</div>
      )}
      {error && <div className="error-message">{error}</div>}
      <div className="wallet-buttons">
        <button 
          onClick={connectEthWallet} 
          disabled={loading || serverStatus === 'offline'}
          className="wallet-btn eth-btn"
        >
          {loading ? 'Connecting...' : 'Connect Ethereum'}
        </button>
        <button 
          onClick={connectSolanaWallet}
          disabled={loading || serverStatus === 'offline'}
          className="wallet-btn solana-btn"
        >
          {loading ? 'Connecting...' : 'Connect Solana'}
        </button>
      </div>
    </div>
  );
};

export default WalletConnector;