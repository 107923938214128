import React, { createContext, useContext, useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { Connection, PublicKey, LAMPORTS_PER_SOL } from '@solana/web3.js';
import { TOKEN_PROGRAM_ID, getAccount } from '@solana/spl-token';

const WalletContext = createContext();

export const WalletProvider = ({ children }) => {
  const [walletState, setWalletState] = useState({
    connected: false,
    type: null,
    address: null,
    nativeBalance: null,
    tokenBalance: null,
    deployedContract: null,
    contractArtifact: null,
    coinDetails: { symbol: '' },
  });

  const SOLANA_NETWORK = 'https://api.devnet.solana.com';

  const updateWallet = async (type, address) => {
    console.log('Context: Updating wallet', { type, address });
    if (type === 'ethereum' && window.ethereum) {
      try {
        const provider = new ethers.BrowserProvider(window.ethereum);
        const accounts = await provider.listAccounts();
        if (accounts.length > 0) {
          const nativeBalance = ethers.formatEther(await provider.getBalance(accounts[0].address));
          const tokenBalance = walletState.deployedContract ? await fetchEthTokenBalance(accounts[0].address, provider) : null;
          setWalletState(prev => ({
            ...prev,
            connected: true,
            type: 'ethereum',
            address: accounts[0].address,
            nativeBalance,
            tokenBalance,
          }));
        } else {
          disconnectWallet();
        }
      } catch (err) {
        console.error('Context: Ethereum update failed:', err);
        disconnectWallet();
      }
    } else if (type === 'solana' && window.solana) {
      try {
        const publicKey = address || (window.solana.isConnected ? window.solana.publicKey.toString() : null);
        if (publicKey) {
          const connection = new Connection(SOLANA_NETWORK, 'confirmed');
          const nativeBalance = (await connection.getBalance(new PublicKey(publicKey)) / LAMPORTS_PER_SOL).toFixed(2);
          const tokenBalance = walletState.deployedContract ? await fetchSolanaTokenBalance(publicKey, connection) : null;
          setWalletState(prev => ({
            ...prev,
            connected: true,
            type: 'solana',
            address: publicKey,
            nativeBalance,
            tokenBalance,
          }));
        } else {
          disconnectWallet();
        }
      } catch (err) {
        console.error('Context: Solana update failed:', err);
        disconnectWallet();
      }
    } else {
      disconnectWallet();
    }
  };

  const disconnectWallet = () => {
    console.log('Context: Disconnecting wallet');
    setWalletState({
      connected: false,
      type: null,
      address: null,
      nativeBalance: null,
      tokenBalance: null,
      deployedContract: null,
      contractArtifact: null,
      coinDetails: { symbol: '' },
    });
  };

  const setDeployedContract = async (contractAddress) => {
    console.log('Context: Setting deployed contract', contractAddress);
    setWalletState(prev => ({ ...prev, deployedContract: contractAddress }));
    if (walletState.address && walletState.type) {
      await updateWallet(walletState.type, walletState.address); // Refresh balances
    }
  };

  const setContractArtifact = (artifact) => {
    console.log('Context: Setting contract artifact');
    setWalletState(prev => ({ ...prev, contractArtifact: artifact }));
  };

  const setCoinDetails = (details) => {
    console.log('Context: Setting coin details', details);
    setWalletState(prev => ({ ...prev, coinDetails: details }));
  };

  const fetchEthTokenBalance = async (address, provider) => {
    if (!walletState.deployedContract || !walletState.contractArtifact || !walletState.contractArtifact.abi) {
      return 'N/A';
    }
    try {
      const contract = new ethers.Contract(walletState.deployedContract, walletState.contractArtifact.abi, provider);
      const balance = await contract.balanceOf(address);
      return ethers.formatEther(balance);
    } catch (err) {
      console.error('Context: Failed to fetch Ethereum token balance:', err);
      return 'Error';
    }
  };

  const fetchSolanaTokenBalance = async (publicKey, connection) => {
    if (!walletState.deployedContract) {
      return 'N/A';
    }
    try {
      const tokenAccounts = await connection.getTokenAccountsByOwner(new PublicKey(publicKey), {
        mint: new PublicKey(walletState.deployedContract),
      });
      if (tokenAccounts.value.length > 0) {
        const accountInfo = await getAccount(connection, tokenAccounts.value[0].pubkey);
        return (Number(accountInfo.amount) / LAMPORTS_PER_SOL).toFixed(2);
      }
      return '0';
    } catch (err) {
      console.error('Context: Failed to fetch Solana token balance:', err);
      return 'Error';
    }
  };

  useEffect(() => {
    const checkInitialWallet = async () => {
      console.log('Context: Checking initial wallet state');
      if (window.ethereum) {
        const provider = new ethers.BrowserProvider(window.ethereum);
        const accounts = await provider.listAccounts();
        if (accounts.length > 0) {
          await updateWallet('ethereum', accounts[0].address);
        }
      }
      if (window.solana && window.solana.isConnected) {
        await updateWallet('solana', window.solana.publicKey.toString());
      }
    };
    checkInitialWallet();
  }, []);

  return (
    <WalletContext.Provider value={{
      walletState,
      updateWallet,
      disconnectWallet,
      setDeployedContract,
      setContractArtifact,
      setCoinDetails,
    }}>
      {children}
    </WalletContext.Provider>
  );
};

export const useWallet = () => {
  const context = useContext(WalletContext);
  if (!context) {
    throw new Error('useWallet must be used within a WalletProvider');
  }
  return context;
};